import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'

import qs from 'qs'

import filter from 'lodash/filter'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

import channelFragment from 'GraphQL/Fragments/Channel.graphql'
import userFragment from 'GraphQL/Fragments/User.graphql'
import createDirectChannelMutation from 'GraphQL/Mutations/Channels/createDirectChannel.graphql'
import blockUserMutation from 'GraphQL/Mutations/User/blockUser.graphql'
import unblockUserMutation from 'GraphQL/Mutations/User/unblockUser.graphql'
import directChannelsQuery from 'GraphQL/Queries/Channels/directChannels.graphql'
import blockedUsersQuery from 'GraphQL/Queries/User/blockedUsers.graphql'

import { APP_DIRECT_CHANNEL } from 'Router/routes'

import { useMutation, useQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import useAppContext from './useAppContext'

export default function useDirectChannel() {
  const history = useHistory()
  const location = useLocation()
  const { me } = useAppContext()
  const s = useScopedI18n('user.profile')
  const { data } = useQuery(directChannelsQuery, {
    variables: { page: 0, limit: 999999 },
  })
  const [createDirectChannel] = useMutation(createDirectChannelMutation)
  const [blockUser] = useMutation(blockUserMutation)
  const [unblockUser] = useMutation(unblockUserMutation)

  const query = qs.parse(location.search.replace('?', ''))

  const channels = useMemo(
    () => data?.directChannels?.rows ?? {},
    [data?.directChannels],
  )

  const channelsParticipants = useMemo(
    () =>
      reduce(
        channels,
        (result, value) => [
          ...result,
          ...map(
            filter(
              value?.participants,
              participant => participant?.user?.id !== me?.id,
            ),
            participant => ({
              userId: participant?.user?.id,
              channelId: participant?.channel?.id,
            }),
          ),
        ],
        [],
      ),
    [channels, me?.id],
  )

  const handleCreateChannel = useCallback(
    async userId => {
      try {
        const response = await createDirectChannel({ variables: { userId } })

        if (query?.returnUrl) {
          history.push(
            `${APP_DIRECT_CHANNEL(
              response?.data?.createDirectChannel?.id,
            )}?returnUrl=${query?.returnUrl}`,
          )
        } else {
          history.push(
            APP_DIRECT_CHANNEL(response?.data?.createDirectChannel?.id),
          )
        }
      } catch (error) {
        toast?.error({
          title: 'Message',
          text: error?.message,
        })
      }
    },
    [createDirectChannel, history, query],
  )

  const directChannelHandler = useCallback(
    userId => {
      const participantsById = keyBy(channelsParticipants, 'userId')
      if (participantsById[userId]) {
        if (query?.returnUrl) {
          history.push(
            `${APP_DIRECT_CHANNEL(
              participantsById[userId]?.channelId,
            )}?returnUrl=${query?.returnUrl}`,
          )
        } else {
          history.push(APP_DIRECT_CHANNEL(participantsById[userId]?.channelId))
        }
      } else {
        handleCreateChannel(userId)
      }
    },
    [channelsParticipants, handleCreateChannel, history, query],
  )

  const blockUserHandler = useCallback(
    (channelId, blockUserId) => {
      blockUser({
        variables: { channelId, blockUserId },
        update(cache) {
          const storedData = cache.readQuery({
            query: blockedUsersQuery,
          })
          const blockedUserFragemt = {
            id: `User:${blockUserId}`,
            fragmentName: 'UserFields',
            fragment: userFragment,
          }
          const blockedChannelFragment = {
            id: `Channel:${channelId}`,
            fragmentName: 'ChannelFields',
            fragment: channelFragment,
          }
          const blockedUserData = cache.readFragment(blockedUserFragemt)
          const blockedChannelData = cache.readFragment(blockedChannelFragment)

          // add blocked user to cache
          cache.writeQuery({
            query: blockedUsersQuery,
            data: {
              getBlockedUsers: {
                ...storedData.getBlockedUsers,
                rows: [
                  ...storedData.getBlockedUsers.rows,
                  {
                    blockedUser: blockedUserData,
                    blockedUserId: blockUserId,
                    channelId,
                    createdAt: new Date().toISOString(),
                    id: blockUserId,
                    updatedAt: new Date().toISOString(),
                  },
                ],
              },
            },
          })

          // update channel as blocked
          cache.writeFragment({
            ...blockedChannelFragment,
            data: {
              ...blockedChannelData,
              isBlocked: true,
            },
          })
        },
      })
        .then(() => {
          toast?.success({
            title: 'Success',
            text: s('events.blocked'),
          })
        })
        .catch(error => {
          toast?.error({
            title: 'Error',
            text: error?.message,
          })
        })
    },
    [blockUser, s],
  )

  const unblockUserHandler = useCallback(
    (channelId, unblockUserId) =>
      unblockUser({
        variables: { channelId, unblockUserId },
        update(cache) {
          const storedData = cache.readQuery({
            query: blockedUsersQuery,
          })

          const blockedChannelFragment = {
            id: `Channel:${channelId}`,
            fragmentName: 'ChannelFields',
            fragment: channelFragment,
          }
          const blockedChannelData = cache.readFragment(blockedChannelFragment)

          // remove unblocked user from cache
          cache.writeQuery({
            query: blockedUsersQuery,
            data: {
              getBlockedUsers: {
                ...storedData.getBlockedUsers,
                rows: [
                  ...filter(
                    storedData.getBlockedUsers.rows,
                    item => item.blockedUserId !== unblockUserId,
                  ),
                ],
              },
            },
          })

          // update channel as unblocked
          cache.writeFragment({
            ...blockedChannelFragment,
            data: {
              ...blockedChannelData,
              isBlocked: false,
            },
          })
        },
      })
        .then(() => {
          toast?.success({
            title: 'Success',
            text: s('events.unblocked'),
          })
        })
        .catch(error => {
          toast?.error({
            title: 'Error',
            text: error?.message,
          })
        }),
    [s, unblockUser],
  )

  return {
    openDirectChannel: directChannelHandler,
    blockChannel: blockUserHandler,
    unblockChannel: unblockUserHandler,
  }
}
