import React from 'react'
import PropTypes from 'prop-types'

import General from './General'
import { Container } from './styles'

function Info({ darkMode, userId, username, me, profile, isCollapsed }) {
  // const [{ exclusiveMode, roomGroupMeta }] = useEntranceContext()

  /* const showSocialVerificationOptions = useMemo(() => {
    if (!exclusiveMode) {
      return true
    }
    return (
      roomGroupMeta?.showSocialLinks &&
      find(roomGroupMeta?.socialLinks, method => method?.show)
    )
  }, [exclusiveMode, roomGroupMeta]) */

  return (
    <Container collapsed={isCollapsed}>
      <General
        createdAt={me?.createdAt}
        isCollapsed={isCollapsed}
        profile={profile}
        userId={userId}
        username={username}
      />
      {/* {showSocialVerificationOptions && (
        <SocialAccounts
          darkMode={darkMode}
          isCollapsed={isCollapsed}
          user={me}
        />
      )} */}
      {/* <Links isCollapsed={isCollapsed} /> */}
    </Container>
  )
}

Info.defaultProps = {
  darkMode: false,
}

Info.propTypes = {
  darkMode: PropTypes.bool,
  isCollapsed: PropTypes.bool.isRequired,
  me: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
}

export default Info
