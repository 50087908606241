import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { useStoreon } from 'storeon/react'

import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import without from 'lodash/without'

import { ChannelCategoryModal } from 'Components/Blocks/User/Modals'
import { Button, Loader } from 'Components/UI'

import { UI_ACTIONS, UI_STATE } from 'Constants/store'

import categorizedChannelsQuery from 'GraphQL/Queries/Channels/categorizedChannels.graphql'

import { useAppContext } from 'Hooks'

import RoomPolicy from 'Policies/Room'

import { useLazyQuery } from 'Services/Apollo'

import Categories from './Categories'
import Header from './Header'
import {
  ButtonWrapper,
  CollapseArrow,
  Container,
  ScrollingContainer,
} from './styles'
import Team from './Team'

import { Subscriptions } from '../Subscriptions'

function Channels({ room }) {
  const { me } = useAppContext()
  const { ui, dispatch } = useStoreon(UI_STATE)
  const [channelCategoryModal, setChannelCategoryModal] = useState({
    isEdit: false,
    isOpen: false,
    entity: null,
  })

  const channelsCollapsed = ui?.channelsCollapsed

  const [
    loadChannelCategories,
    { data, loading, variables: channelCategoriesVariables },
  ] = useLazyQuery(categorizedChannelsQuery, { fetchPolicy: 'network-only' })

  const channelCategories = useMemo(
    () => data?.categorizedChannels ?? {},
    [data?.categorizedChannels],
  )

  const orderedChannels = useMemo(() => {
    const orderedCategorizes = orderBy(
      data?.categorizedChannels,
      ['sortOrder'],
      ['asc'],
    )
    return orderBy(orderedCategorizes[0]?.channels, ['sortOrder'], ['asc'])
  }, [data?.categorizedChannels])

  const channelId = useMemo(
    () => orderedChannels[0]?.id || null,
    [orderedChannels],
  )

  const canChangeChannels = useMemo(
    () => RoomPolicy.canChangeChannels(room, me),
    [me, room],
  )

  const fetchData = useCallback(() => {
    loadChannelCategories({ variables: { roomId: room?.roomId } })
  }, [loadChannelCategories, room?.roomId])

  useEffect(() => {
    if (room?.roomId) {
      fetchData()
    }
  }, [fetchData, room?.roomId])

  const handleChannelCategoryModal = useCallback(entity => {
    setChannelCategoryModal({ isEdit: false, isOpen: true, entity })
  }, [])
  const handleEditChannelCategory = useCallback(entity => {
    setChannelCategoryModal({ isEdit: true, isOpen: true, entity })
  }, [])
  const handleCloseChannelCategoryeModal = useCallback(() => {
    setChannelCategoryModal({ isEdit: false, isOpen: false, entity: null })
  }, [])

  const handleCollapseChannels = useCallback(() => {
    if (includes(channelsCollapsed, room?.roomId)) {
      dispatch(UI_ACTIONS.SET, {
        channelsCollapsed: without(channelsCollapsed, room?.roomId),
      })
    } else {
      dispatch(UI_ACTIONS.SET, {
        channelsCollapsed: [...(channelsCollapsed || []), room?.roomId],
      })
    }
  }, [channelsCollapsed, dispatch, room?.roomId])

  return (
    <Container>
      {!includes(channelsCollapsed, room?.roomId) && (
        <>
          <Header
            canChangeChannels={canChangeChannels}
            channelId={channelId}
            room={{ id: room?.roomId, label: room?.label, slug: room?.slug }}
            onChannelCategory={handleChannelCategoryModal}
          />

          {loading ? (
            <Loader alignSelfCenter mt={6} />
          ) : (
            <ScrollingContainer>
              {!room?.locked && (
                <Categories
                  canChangeChannels={canChangeChannels}
                  categories={channelCategories}
                  room={{ id: room?.roomId, slug: room?.slug }}
                  onEditCategory={handleEditChannelCategory}
                />
              )}
              <Team owners={room?.owners} />
              <Team moderators={room?.moderators} />
            </ScrollingContainer>
          )}
        </>
      )}

      <ButtonWrapper
        secondaryPosition={includes(channelsCollapsed, room?.roomId)}
      >
        <Button arrows icon small onClick={handleCollapseChannels}>
          <CollapseArrow
            arrowSelected={includes(channelsCollapsed, room?.roomId)}
            strokeWidth={2}
          />
        </Button>
      </ButtonWrapper>

      <ChannelCategoryModal
        data={channelCategoryModal?.entity}
        isEdit={channelCategoryModal?.isEdit}
        isOpen={channelCategoryModal?.isOpen}
        onClose={handleCloseChannelCategoryeModal}
      />

      <Subscriptions channelCategoriesVariables={channelCategoriesVariables} />
    </Container>
  )
}

Channels.defaultProps = {
  room: null,
}

Channels.propTypes = {
  room: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Channels
