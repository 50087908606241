import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { useStoreon } from 'storeon/react'

import includes from 'lodash/includes'
import without from 'lodash/without'

import { SetUsernameModal } from 'Components/Blocks/Modals'
import AsideProfile from 'Components/Blocks/User/AsideProfile'
import ChannelsAndLounges from 'Components/Blocks/User/ChannelsAndLounges'
import NavMenu from 'Components/Blocks/User/NavMenu'

import { ASIDE_MENU_STATES } from 'Constants/ids'
import { UI_ACTIONS, UI_STATE } from 'Constants/store'
import { APP_FALLBACK } from 'Constants/strings'

import {
  useAppContext,
  useEntranceContext,
  useLocationQueryParams,
  useRoomData,
} from 'Hooks'

import {
  APP_MY_FEED,
  APP_ROOM_CHANNEL,
  APP_ROOM_POST,
  APP_ROOM_POSTS,
  APP_ROOM_STORE,
} from 'Router/routes'

import {
  Container,
  ContainerChannels,
  ContainerControlLeft,
  ContainerControlRight,
  Content,
  PageContent,
} from './styles'

function App({ route }) {
  const { ui, dispatch } = useStoreon(UI_STATE)

  const history = useHistory()
  const { pathname, state } = useLocation()
  const { me } = useAppContext()

  const [{ roomGroupMeta, roomMeta, exclusiveMode }] = useEntranceContext()

  const {
    leftIsCollapsed,
    rightIsCollapsed,
    channelsCollapsed,
    asideMenuDisplay,
  } = ui

  const pathnameArray = pathname?.split('/')

  const queryParams = useLocationQueryParams()

  const myFeedPage = matchPath(pathname, {
    path: APP_MY_FEED,
    exact: true,
    strict: false,
  })

  const roomPostsPage = matchPath(pathname, {
    path: APP_ROOM_POSTS(),
    exact: true,
    strict: false,
  })

  const roomPostPage = matchPath(pathname, {
    path: APP_ROOM_POST(),
    exact: true,
    strict: false,
  })

  const roomChannelPage = matchPath(pathname, {
    path: APP_ROOM_CHANNEL(),
    exact: true,
    strict: false,
  })

  const [usernameModal, setUsernameModal] = useState({ isOpen: false })

  const handleSetUsername = useCallback(() => {
    setUsernameModal({ isOpen: true })
  }, [])
  const handleCloseUsernameModal = useCallback(() => {
    setUsernameModal({ isOpen: false })
  }, [])

  useEffect(() => {
    if (me && !me?.username) {
      dispatch(UI_ACTIONS.SET, {
        displayBalance: true,
      })
      handleSetUsername()
    }
  }, [dispatch, handleSetUsername, me])

  const handleFallbackRedirect = useCallback(() => {
    const fallbackArray = without(pathnameArray, APP_FALLBACK)
    const fallback = fallbackArray?.join('/')
    if (state) {
      history.replace({ pathname: fallback, state })
    } else {
      history.replace(fallback)
    }
  }, [history, pathnameArray, state])

  useEffect(() => {
    if (pathnameArray?.[1] === APP_FALLBACK) {
      handleFallbackRedirect()
    }
  }, [handleFallbackRedirect, pathnameArray])

  const roomPointer =
    roomPostsPage?.params?.roomPointer ||
    roomPostPage?.params?.roomPointer ||
    roomChannelPage?.params?.roomPointer

  const { roomId, locked } = useRoomData(roomPointer)

  const roomStorePage = matchPath(pathname, {
    path: APP_ROOM_STORE(),
    exact: true,
    strict: false,
  })
  // todo refactore condition for channels!

  const isHideSidebar = useMemo(
    () =>
      exclusiveMode &&
      roomGroupMeta?.rooms?.length === 1 &&
      roomMeta?.settings?.hideSidebar,
    [exclusiveMode, roomGroupMeta, roomMeta],
  )

  return (
    <Container>
      <ContainerControlLeft
        collapsedLeft={leftIsCollapsed}
        hideControlLeft={isHideSidebar}
      >
        <NavMenu isCollapsed={leftIsCollapsed} />
      </ContainerControlLeft>
      {!myFeedPage?.isExact &&
        asideMenuDisplay !== ASIDE_MENU_STATES.INBOX &&
        (roomPostsPage?.isExact ||
          roomPostPage?.isExact ||
          roomChannelPage?.isExact) &&
        !locked && (
          <ContainerChannels
            collapsedChannels={includes(channelsCollapsed, roomId)}
          >
            <ChannelsAndLounges />
          </ContainerChannels>
        )}
      <Content>
        <PageContent
          fillWidth={queryParams?.full}
          noSpaceLeft={roomStorePage?.isExact && !queryParams?.full}
        >
          {renderRoutes(route.routes)}
        </PageContent>
      </Content>
      <ContainerControlRight collapsedRight={rightIsCollapsed}>
        <AsideProfile isCollapsed={rightIsCollapsed} />
      </ContainerControlRight>
      <SetUsernameModal
        isOpen={usernameModal?.isOpen}
        onClose={handleCloseUsernameModal}
      />
    </Container>
  )
}

App.propTypes = {
  route: PropTypes.object.isRequired,
}

export default App
