import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div.attrs({
  as: 'section',
})`
  position: relative;
  display: flex;
  flex-direction: row;
  background: ${themeGet('colors.bg.default')};
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

const collapsedLeftCss = ({ collapsedLeft }) =>
  collapsedLeft &&
  css`
    width: 9%;
    transition: all ${themeGet('transitionTime.default')} ease-in-out;
    padding-left: 0;
  `

const collapsedRightCss = ({ collapsedRight }) =>
  collapsedRight &&
  css`
    width: 9%;
    transition: all ${themeGet('transitionTime.default')} ease-in-out;
    padding-right: 0;
  `

const displayLeftCss = ({ collapsedLeft }) =>
  !collapsedLeft &&
  css`
    left: 0;
    width: 100%;
    transition: all ${themeGet('transitionTime.default')} ease-in-out;
  `

const hideControlLeftCss = ({ hideControlLeft }) =>
  hideControlLeft &&
  css`
    display: none;
  `

const displayRightCss = ({ collapsedRight }) =>
  !collapsedRight &&
  css`
    right: 0;
    width: 100%;
    transition: all ${themeGet('transitionTime.default')} ease-in-out;
  `

export const ContainerControl = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100vh;
  padding-top: ${themeGet('space.5')}px;
  padding-left: ${themeGet('space.5')}px;
  padding-right: ${themeGet('space.5')}px;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  @media (max-width: ${themeGet('breakpoints.3')}) {
    position: absolute;
    top: 0;
    background: ${themeGet('colors.bg.default')};
    z-index: 100;
  }

  @media (min-width: ${themeGet('breakpoints.3')}) {
    ${collapsedLeftCss}
    ${collapsedRightCss}
  }

  ${layout}
`

export const ContainerControlLeft = styled(ContainerControl)`
  @media (max-width: ${themeGet('breakpoints.3')}) {
    left: -30%;

    ${displayLeftCss}
  }

  ${hideControlLeftCss}
  ${layout}
`

export const ContainerControlRight = styled(ContainerControl)`
  @media (max-width: ${themeGet('breakpoints.3')}) {
    right: -30%;

    ${displayRightCss}
  }

  ${layout}
`

export const Content = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  flex: 1 0;
  background-color: ${themeGet('colors.bg.default')};
  border-left: 1px solid ${themeGet('colors.border.default')};
  border-right: 1px solid ${themeGet('colors.border.default')};

  @media (max-width: ${themeGet('breakpoints.3')}) {
    max-width: 100%;
  }
`

const noSpaceLeftCss = ({ noSpaceLeft }) =>
  noSpaceLeft &&
  css`
    padding: 0;
    margin-left: 0;
    max-width: 100%;
  `
const fillWidthCss = ({ fillWidth }) =>
  fillWidth &&
  css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    z-index: 3;
    border: none;
    margin: 0;
    padding: 0;
    background-color: ${themeGet('colors.bg.primary')};
    transition: none;
  `

export const PageContent = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 690px;
  margin: auto;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;
  padding: 0 ${themeGet('space.3')}px;

  @media (max-width: ${themeGet('breakpoints.3')}) {
    max-width: 100%;
    padding: 0;
  }

  ${noSpaceLeftCss}
  ${fillWidthCss}
`

const collapsedChannelsCss = ({ collapsedChannels }) =>
  collapsedChannels &&
  css`
    width: 0;
    padding: 0;
  `

const displayChannelsCss = ({ collapsedChannels }) =>
  !collapsedChannels &&
  css`
    left: 0;
    width: 100%;
  `

export const ContainerChannels = styled.div`
  height: 100%;
  width: 190px;
  border-left: 1px solid ${themeGet('colors.border.default')};
  transition: all ${themeGet('transitionTime.default')} ease-in-out;
  padding: ${themeGet('space.4')}px;

  @media (max-width: ${themeGet('breakpoints.3')}) {
    position: absolute;
    top: 0;
    background: ${themeGet('colors.bg.primary')};
    z-index: 100;

    ${displayChannelsCss}
  }

  ${collapsedChannelsCss}

  ${layout}
`
