import React, { useCallback, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { matchPath, useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import { useApolloClient } from '@apollo/client'

import noop from 'lodash/noop'

import { Row, Text } from 'Components/UI'

import joinGroupChannelMutation from 'GraphQL/Mutations/Channels/joinGroupChannel.graphql'
import canJoinGroupChannelQuery from 'GraphQL/Queries/Channels/canJoinGroupChannel.graphql'

import { APP_ROOM_CHANNEL } from 'Router/routes'

import { useMutation } from 'Services/Apollo'
import toast from 'Services/Toast'

import { UnreadCounter } from './Shared'
import { ChannelName, LockIcon } from './styles'

function Channel({
  channel,
  provided,
  isRoomLocked,
  roomSlug,
  onResponsiveCollapse,
}) {
  const history = useHistory()
  const client = useApolloClient()
  const { pathname } = useLocation()
  const { breakpoints } = useTheme()

  const selectedChannel = matchPath(pathname, {
    path: APP_ROOM_CHANNEL(),
    exact: true,
    strict: false,
  })

  const channelPointer = selectedChannel?.params?.channelPointer ?? ''

  const [joinGroupChannel] = useMutation(joinGroupChannelMutation)

  const handleJoinGroupChannel = useCallback(
    async ({ channelId, locked, free }) => {
      if (isMobile || window?.innerWidth < parseInt(breakpoints[3], 10)) {
        onResponsiveCollapse()
      }
      if ((isRoomLocked && free) || locked) {
        try {
          const { data: joinData } = await client.query({
            query: canJoinGroupChannelQuery,
            variables: { channelId },
            fetchPolicy: 'network-only',
          })

          if (joinData?.canJoinGroupChannel?.ok) {
            try {
              await joinGroupChannel({ variables: { channelId } })
            } catch (error) {
              toast.error({
                title: 'Cannot join the Channel',
                text: error?.message,
              })
            }
          }
        } catch (error) {
          //
        }
      }
      history.push(APP_ROOM_CHANNEL(roomSlug, channelId))
    },
    [
      breakpoints,
      client,
      history,
      isRoomLocked,
      joinGroupChannel,
      onResponsiveCollapse,
      roomSlug,
    ],
  )

  const channelAppendix = useMemo(() => {
    if (channel?.locked && channel?.gatingRule?.accessRules?.length)
      return (
        <Row alignSelfCenter>
          <LockIcon />
        </Row>
      )
    return <UnreadCounter unreadCount={channel?.unreadCount} />
  }, [channel])

  return (
    <ChannelName
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      center
      selectedChannel={channelPointer === channel?.id}
      spaceBetween
      width={1}
      onClick={() =>
        handleJoinGroupChannel({
          channelId: channel?.id,
          locked: channel?.locked,
          free: channel?.free,
        })
      }
    >
      <Text small>{`# ${channel?.name}`}</Text>
      {channelAppendix}
    </ChannelName>
  )
}

Channel.defaultProps = {
  channel: {},
  isRoomLocked: false,
  provided: null,
  roomSlug: null,
  onResponsiveCollapse: noop,
}

Channel.propTypes = {
  channel: PropTypes.object,
  isRoomLocked: PropTypes.bool,
  provided: PropTypes.object,
  roomSlug: PropTypes.string,
  onResponsiveCollapse: PropTypes.func,
}

export default Channel
