import React from 'react'
import PropTypes from 'prop-types'

import isNil from 'lodash/isNil'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { AuthMethodIcon, Button, Column, Row, Text } from 'Components/UI'

import { AUTH_METHODS } from 'Constants/ids'

import useMetaWalletsList from 'Hooks/useMetaWalletsList'

import { useScopedI18n } from 'Services/I18n'

import { ConnectWrapper } from '../styles'

function SignInOptions({
  onStep,
  loading,
  setLoading,
  authMethods,
  hideInternalAuth,
}) {
  const s = useScopedI18n('public.modal.inviteToRoom')
  const metaWalletsList = useMetaWalletsList({
    authMethods,
    loading,
    setLoading,
  })

  return (
    <Column center fullWidth>
      {!hideInternalAuth && (
        <>
          <Button
            height={48}
            mt={5}
            outline
            width={230}
            onClick={() => onStep(1)}
          >
            {s('actions.title')}
          </Button>
          {metaWalletsList.length !== 0 && (
            <Text grayLight light my={5}>
              {s('actions.subtitle')}
            </Text>
          )}
        </>
      )}
      <Row alignSelfCenter justifyCenter>
        {map(metaWalletsList, ({ key, disabled, onClick }) => {
          switch (key) {
            case AUTH_METHODS.PRIVY:
              return (
                <Button
                  height={48}
                  mt={5}
                  outline
                  width={230}
                  onClick={onClick}
                >
                  {s('actions.title')}
                </Button>
              )
            case AUTH_METHODS.HATHORPAY:
              return null
            case AUTH_METHODS.COINMETRO:
              return null
            case AUTH_METHODS.COINBASE:
              return null
            default:
              return (
                <ConnectWrapper
                  disabled={disabled}
                  key={key}
                  mt={3}
                  mx={5}
                  onClick={onClick}
                >
                  <AuthMethodIcon kind={key} size={40} />
                </ConnectWrapper>
              )
          }
        })}
      </Row>
      {map(metaWalletsList, ({ key, warning, text }) =>
        key === AUTH_METHODS.HATHORPAY ? null : (
          <>
            {!isNil(warning) && (
              <Text mt={3} small warning>
                {text}: {warning}
              </Text>
            )}
          </>
        ),
      )}
    </Column>
  )
}

SignInOptions.defaultProps = {
  authMethods: [],
  hideInternalAuth: false,
  loading: false,
  setLoading: noop,
  onStep: noop,
}

SignInOptions.propTypes = {
  authMethods: PropTypes.array,
  hideInternalAuth: PropTypes.bool,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  onStep: PropTypes.func,
}

export default SignInOptions
