import uniqBy from 'lodash/uniqBy'

import ChannelMessageFragment from 'GraphQL/Fragments/ChannelMessage.graphql'

import messagesQuery from '../Queries/Channels/messages.graphql'

export function updateMessageQuery(client, createdMessage, messagesVariables) {
  if (!createdMessage) return
  const messagesOptions = {
    query: messagesQuery,
    variables: {
      ...messagesVariables,
    },
  }

  const messagesData = client.readQuery(messagesOptions)

  client.writeQuery({
    ...messagesOptions,
    data: {
      ...messagesData,
      messages: {
        ...messagesData?.messages,
        rows: uniqBy(
          [createdMessage, ...(messagesData?.messages?.rows || [])],
          'id',
        ),
      },
    },
  })
}

export function messagePinnedUpdater(messageId, isPinned) {
  return function updateCache(cache) {
    const id = `ChannelMessage:${messageId}`

    const fragment = {
      id,
      fragmentName: 'ChannelMessageFields',
      fragment: ChannelMessageFragment,
    }

    const data = cache.readFragment(fragment)

    if (data) {
      cache.writeFragment({
        ...fragment,
        data: {
          ...data,
          isPinned,
        },
      })
    }
  }
}

export function updateMessagesQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    messages: {
      ...prev?.messages,
      cursor: fetchMoreResult?.messages?.cursor,
      rows: [
        ...(prev?.messages?.rows || []),
        ...(fetchMoreResult?.messages?.rows || []),
      ],
    },
  }
}
