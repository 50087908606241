import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { omit, pick } from '@styled-system/props'
import { themeGet } from '@styled-system/theme-get'

import includes from 'lodash/includes'
import map from 'lodash/map'

import Avatar from 'Components/Blocks/Avatar'

import useEventBusSubscribe from 'Hooks/useEventBusSubscribe'

import EventBus from 'Services/EventBus'

import { Container, SmileIcon } from './styles'

import Button from '../../Button'
import EmojiPicker from '../../EmojiPicker'
import { Column } from '../../Flex'
import { ErrorWrapper } from '../styles'

const noResizeCss = ({ noResize }) =>
  noResize &&
  css`
    .mentions {
      &__input {
        resize: none;
      }
    }
  `

const squeezeCss = ({ squeeze }) =>
  squeeze &&
  css`
    .mentions {
      &__input {
        height: 84px;
      }
    }
  `

const requestBanCss = ({ requestBan }) =>
  requestBan &&
  css`
    .mentions {
      &__input {
        height: 160px !important;
        border: 1px solid ${mapToTheme('inputs.border.input')};
        border-radius: 16px;
        padding: ${themeGet('space.4')}px;
      }
    }
  `

const userBioCss = ({ userBio }) =>
  userBio &&
  css`
    height: 91px;
    max-height: 91px;
    max-width: 350px;
    border: 1px solid ${themeGet('inputs.border.input.default')};
    border-radius: 4px;

    .mentions {
      width: 330px;

      &__input {
        max-height: 91px;
        padding: ${themeGet('space.3')}px;
      }

      &__highlighter {
        max-height: 91px;
        padding: ${themeGet('space.3')}px;
      }
    }

    @media (max-width: ${themeGet('breakpoints.3')}) {
      max-width: 100%;

      .mentions {
        width: calc(100% - 20px);
      }
    }
  `

const valueCss = ({ value, userBio }) =>
  value &&
  !userBio &&
  css`
    @media (max-width: ${themeGet('breakpoints.3')}) {
      max-width: 100%;

      .mentions {
        max-width: calc(100% - 70px);
      }
    }
  `

const fillWidthCss = ({ fillWidth }) =>
  fillWidth &&
  css`
    max-width: 100%;
  `

const withBorderCss = ({ withBorder }) =>
  withBorder &&
  css`
    padding: 0 12px;
    border: 1px solid ${themeGet('colors.border.default')};
    border-radius: 18px;
  `

export const TextArea = styled.div`
  width: 100%;
  max-width: calc(100% - 70px);
  max-height: 500px;
  outline: none;
  border: none;
  background: none;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  .mentions {
    width: 100%;
    font-weight: ${themeGet('text.fontWeight.light')};
    font-size: 14px;
    line-height: 17px;
    letter-spacing: normal;

    &__input {
      border: none;
      outline: none;
      background: none;
      margin: 0 !important;
      max-height: 350px;
      padding: ${themeGet('space.4')}px 1px ${themeGet('space.3')}px;
      caret-color: ${themeGet('colors.font.secondary')};
      color: transparent;
      font-weight: ${themeGet('text.fontWeight.light')};
      font-size: 14px !important;
      line-height: 17px;
      letter-spacing: normal !important;
      overflow-y: scroll !important;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      ::placeholder {
        color: ${themeGet('colors.font.default')};
        font-weight: 300;
        max-width: 100%;
      }

      &::value {
        display: none;
      }

      ${layout}
    }

    &__highlighter {
      max-height: 350px;
      padding-top: ${themeGet('space.4')}px;
      padding-bottom: ${themeGet('space.3')}px;

      overflow-y: scroll !important;
      scrollbar-width: none;
      overflow-wrap: anywhere !important;

      &::-webkit-scrollbar {
        display: none;
      }

      &__substring {
        visibility: visible !important;
        color: ${themeGet('colors.font.secondary')};
      }

      ${layout}
    }
  }

  .user-mention {
    color: ${themeGet('text.color.custom')};
  }

  ${noResizeCss}
  ${squeezeCss}
  ${requestBanCss}
  ${userBioCss}
  ${valueCss}
  ${fillWidthCss}
  ${withBorderCss}

  ${layout}
`

const activeCss = ({ active }) =>
  active &&
  css`
    background-color: ${themeGet('colors.bg.customSecondary')};
  `

export const Suggestion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${themeGet('space.1')}px;
  border-radius: 5px;

  ${activeCss}
`

const secondaryPositionCss = ({ secondaryPosition }) =>
  secondaryPosition &&
  css`
    right: 54px;
  `

const alternatePositionCss = ({ alternatePosition }) =>
  alternatePosition &&
  css`
    right: 16px;
  `

const thirdPositionCss = ({ thirdPosition }) =>
  thirdPosition &&
  css`
    right: 8px;
    bottom: 8px;
  `

export const EmojiWrapper = styled(Column)`
  position: absolute;
  bottom: 10px;
  right: 92px;

  ${alternatePositionCss}
  ${secondaryPositionCss}
  ${thirdPositionCss}
`

function Textarea({
  attachments,
  comment,
  defaultValue,
  isForChannelMessages,
  isForDirectChannel,
  disabled,
  error,
  icon,
  label,
  mentions,
  value,
  search,
  secondary,
  setParentHeight,
  setMentionsHeight,
  suggestionsRef,
  fetchFollowers,
  fetchParticipatedUsers,
  roomId,
  withEmojis,
  userBio,
  onChangeMentions,
  noChange,
  onChange,
  onClear,
  onSubmit,
  requestBan,
  ...rest
}) {
  const textAreaRef = useRef(null)

  const [textAreaHeight, setTextAreaHeight] = useState('51px')
  const [wrapperHeight, setWrapperHeight] = useState('51px')
  const [selectionCaret, setSelectionCaret] = useState({ start: 0, end: 0 })
  const [caretEvent, setCaretEvent] = useState(null)

  const styledProps = pick(rest)
  const inputProps = omit(rest)

  useEffect(() => {
    if (mentions?.length > 0 && value) {
      map(mentions, mention => {
        if (
          !includes(value, mention?.entityId) &&
          !includes(value, `@${mention?.content}`)
        ) {
          onChangeMentions(mention?.entityId, mention?.display, true)
        }
      })
    }
  }, [mentions, onChangeMentions, value])

  useEffect(() => {
    if (userBio) {
      setTextAreaHeight?.('67px')
      setWrapperHeight?.('91px')
      return
    }
    if (requestBan) {
      return
    }
    if (!value && !attachments) {
      setParentHeight?.('51px')
      setTextAreaHeight('51px')
      setWrapperHeight('51px')
      if (setMentionsHeight) {
        setMentionsHeight('0')
      }
    } else if (attachments && textAreaRef?.current?.scrollHeight <= 350) {
      setParentHeight?.(`${textAreaRef.current?.scrollHeight + 96}px`)
      setTextAreaHeight(`${textAreaRef.current?.scrollHeight}px`)
      setWrapperHeight(`${textAreaRef.current?.scrollHeight}px`)
    } else if (!attachments && textAreaRef?.current?.scrollHeight <= 350) {
      setParentHeight?.(`${textAreaRef.current?.scrollHeight}px`)
      setTextAreaHeight(`${textAreaRef.current?.scrollHeight}px`)
      setWrapperHeight(`${textAreaRef.current?.scrollHeight}px`)
    } else if (attachments && textAreaRef?.current?.scrollHeight > 350) {
      setParentHeight?.('446px')
      setTextAreaHeight('350px')
      setWrapperHeight('350px')
    } else {
      setParentHeight?.('350px')
      setTextAreaHeight('350px')
      setWrapperHeight('350px')
    }
  }, [
    attachments,
    value,
    setParentHeight,
    requestBan,
    setMentionsHeight,
    userBio,
  ])

  const handleSelectionCaret = useCallback(
    event => {
      const prevValue = selectionCaret
      const prevEvent = caretEvent
      if (event?.code === 'Enter' && !event?.shiftKey && !event?.ctrlKey) {
        event?.preventDefault()
        if (onSubmit) {
          onSubmit()
        }
      } else if (event?.target === undefined) {
        setSelectionCaret({
          start: prevValue?.start + 2,
          end: prevValue?.end + 2,
        })
        setCaretEvent(event)
      } else if (
        event?.target?.selectionStart === undefined &&
        event?.target?.selectionEnd === undefined
      ) {
        setSelectionCaret({
          start: prevValue?.start + 1,
          end: prevValue?.end + 1,
        })
        setCaretEvent(event)
      } else if (
        value &&
        event?.key === 'ArrowLeft' &&
        !event?.shiftKey &&
        prevEvent?.target === undefined
      ) {
        setSelectionCaret({
          start: prevValue?.start - 2,
          end: prevValue?.end - 2,
        })
        setCaretEvent(event)
      } else if (
        value &&
        event?.key === 'ArrowRight' &&
        !event?.shiftKey &&
        prevEvent?.target === undefined
      ) {
        setSelectionCaret({
          start: prevValue?.start + 2,
          end: prevValue?.end + 2,
        })
        setCaretEvent(event)
      } else if (value && event?.key === 'ArrowLeft' && !event?.shiftKey) {
        setSelectionCaret({
          start: prevValue?.start - 1,
          end: prevValue?.end - 1,
        })
        setCaretEvent(event)
      } else if (value && event?.key === 'ArrowRight' && !event?.shiftKey) {
        setSelectionCaret({
          start: prevValue?.start + 1,
          end: prevValue?.end + 1,
        })
        setCaretEvent(event)
      } else {
        setSelectionCaret({
          start: event?.target?.selectionStart,
          end: event?.target?.selectionEnd,
        })
        if (prevEvent?.target !== undefined) {
          setCaretEvent(event)
        }
      }
    },
    [caretEvent, onSubmit, selectionCaret, value],
  )

  const handleChange = useCallback(
    event => {
      if (!requestBan && !userBio) {
        if (textAreaRef?.current?.scrollHeight <= 350) {
          setTextAreaHeight('auto')
          setParentHeight?.(`${textAreaRef.current?.scrollHeight}px`)
          setWrapperHeight(`${textAreaRef.current?.scrollHeight}px`)
          handleSelectionCaret(event)
        } else {
          setTextAreaHeight('350px')
          setParentHeight?.('350px')
          setWrapperHeight('350px')
          handleSelectionCaret(event)
        }
      }

      if (onChange) {
        onChange(event)
      }
    },
    [handleSelectionCaret, onChange, requestBan, setParentHeight, userBio],
  )

  const handleUserSuggestion = useCallback(
    (suggestion, suggestionSearch, highlightedDisplay, index, focused) => {
      if (setMentionsHeight) {
        setMentionsHeight('168px')
      }
      return (
        <Suggestion
          active={focused}
          onClick={!userBio ? () => setMentionsHeight('0') : undefined}
        >
          <Avatar
            mr={3}
            size={40}
            src={suggestion?.photoUrl}
            username={suggestion?.display}
          />
          @{highlightedDisplay}
        </Suggestion>
      )
    },
    [setMentionsHeight, userBio],
  )
  const handleFocus = useCallback(() => {
    textAreaRef && textAreaRef?.current?.focus()
  }, [])

  useEventBusSubscribe(EventBus.actions.textarea.focus, handleFocus)

  const userMention = useCallback((id, display) => `@${display}`, [])

  return (
    <Container
      {...styledProps}
      alternate={userBio}
      minHeight={!requestBan ? wrapperHeight : 'auto'}
    >
      <TextArea
        {...inputProps}
        minHeight={!requestBan ? wrapperHeight : 'auto'}
        requestBan={requestBan}
        userBio={userBio}
        value={value}
      >
        <MentionsInput
          inputRef={textAreaRef}
          {...inputProps}
          allowSuggestionsAboveCursor={!userBio}
          className="mentions"
          disabled={disabled}
          height={!requestBan ? textAreaHeight : '160px'}
          suggestionsPortalHost={
            suggestionsRef ? suggestionsRef?.current : undefined
          }
          value={value}
          onChange={handleChange}
          onClick={event => handleSelectionCaret(event)}
          onKeyDown={event => handleSelectionCaret(event)}
        >
          <Mention
            className="user-mention"
            data={(mentionSearch, callback) =>
              !userBio && !isForDirectChannel
                ? fetchParticipatedUsers(mentionSearch, callback, roomId)
                : fetchFollowers(mentionSearch, callback)
            }
            displayTransform={(id, display) => userMention(id, display)}
            renderSuggestion={(
              suggestion,
              suggestionSearch,
              highlightedDisplay,
              index,
              focused,
            ) =>
              handleUserSuggestion(
                suggestion,
                suggestionSearch,
                highlightedDisplay,
                index,
                focused,
              )
            }
            trigger="@"
            onAdd={(id, display) => onChangeMentions(id, display, false)}
          />
        </MentionsInput>
      </TextArea>
      {withEmojis && !includes(attachments, 'voiceAttachment') && (
        <EmojiWrapper
          alternatePosition={comment}
          secondaryPosition={attachments || value}
          thirdPosition={userBio}
        >
          <EmojiPicker
            caretPosition={selectionCaret}
            offset={!userBio ? [-130, 10] : [-150, -120]}
            trigger="click"
            value={value}
            onEmoji={handleChange}
          >
            <Button height={22} neutral noEffectsIcon width={22}>
              <SmileIcon />
            </Button>
          </EmojiPicker>
        </EmojiWrapper>
      )}

      {error && (
        <ErrorWrapper secondary={secondary} third={userBio}>
          {error}
        </ErrorWrapper>
      )}
    </Container>
  )
}

Textarea.defaultProps = {
  attachments: null,
  autoComplete: 'on',
  autoFocus: false,
  comment: false,
  defaultValue: null,
  disabled: false,
  error: false,
  fetchFollowers: null,
  fetchParticipatedUsers: null,
  fillWidth: false,
  icon: null,
  isForChannelMessages: null,
  isForDirectChannel: false,
  label: null,
  mentions: [],
  noChange: false,
  placeholder: '',
  requestBan: false,
  roomId: null,
  search: false,
  secondary: true,
  setMentionsHeight: null,
  setParentHeight: null,
  small: false,
  suggestionsRef: null,
  type: 'text',
  userBio: false,
  value: undefined,
  withEmojis: false,
  withHighlights: false,
  withBorder: false,
  onChange: null,
  onChangeMentions: null,
  onClear: null,
  onEmoji: null,
  onSubmit: null,
}

Textarea.propTypes = {
  attachments: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  comment: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  fetchFollowers: PropTypes.func,
  fetchParticipatedUsers: PropTypes.func,
  fillWidth: PropTypes.bool,
  icon: PropTypes.node,
  isForChannelMessages: PropTypes.string,
  isForDirectChannel: PropTypes.bool,
  label: PropTypes.string,
  mentions: PropTypes.array,
  noChange: PropTypes.bool,
  placeholder: PropTypes.string,
  requestBan: PropTypes.bool,
  roomId: PropTypes.string,
  search: PropTypes.bool,
  secondary: PropTypes.bool,
  setMentionsHeight: PropTypes.func,
  setParentHeight: PropTypes.func,
  small: PropTypes.bool,
  suggestionsRef: PropTypes.instanceOf(Element),
  type: PropTypes.string,
  userBio: PropTypes.bool,
  value: PropTypes.string,
  withBorder: PropTypes.bool,
  withEmojis: PropTypes.bool,
  withHighlights: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeMentions: PropTypes.func,
  onClear: PropTypes.func,
  onEmoji: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default Textarea
