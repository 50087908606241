import React, { useCallback, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { matchPath, useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'

import { FiChevronLeft, FiPlus } from 'react-icons/fi'
import { VscMenu } from 'react-icons/vsc'

import { useTheme } from 'styled-components'

import { useStoreon } from 'storeon/react'

import noop from 'lodash/noop'

import Avatar from 'Components/Blocks/Avatar'
import { Button, Column, Row, Text } from 'Components/UI'

import { UI_ACTIONS, UI_STATE } from 'Constants/store'

import { useAppContext, useEntranceContext } from 'Hooks'

import { APP_ROOM_CHANNEL, APP_ROOM_POSTS } from 'Router/routes'

import { IconWrapper, NavButtonExclusiveMode, RoomListWrapper } from './styles'

function Header({ canChangeChannels, room, onChannelCategory, channelId }) {
  const { me } = useAppContext()
  const [{ roomGroupMeta, roomMeta, exclusiveMode }] = useEntranceContext()
  const { pathname } = useLocation()

  const { dispatch, ui } = useStoreon(UI_STATE)

  const history = useHistory()
  const theme = useTheme()
  const { darkMode } = ui

  const { rightIsCollapsed, leftIsCollapsed, channelsCollapsed } = ui

  const roomChannelsPage = matchPath(pathname, {
    path: APP_ROOM_CHANNEL(),
    exact: true,
    strict: true,
  })

  const roomPostsPage = matchPath(pathname, {
    path: APP_ROOM_POSTS(),
    exact: true,
    strict: true,
  })

  const handleCollapsedLeft = useCallback(() => {
    dispatch(UI_ACTIONS.SET, {
      leftIsCollapsed: !leftIsCollapsed,
      channelsCollapsed: [...(channelsCollapsed || []), room?.id],
    })
  }, [channelsCollapsed, dispatch, leftIsCollapsed, room])

  const handleCollapsedRight = useCallback(() => {
    dispatch(UI_ACTIONS.SET, {
      rightIsCollapsed: !rightIsCollapsed,
      channelsCollapsed: [...(channelsCollapsed || []), room?.id],
    })
  }, [channelsCollapsed, dispatch, rightIsCollapsed, room])

  const handleCollapseChannels = useCallback(() => {
    dispatch(UI_ACTIONS.SET, {
      channelsCollapsed: [...(channelsCollapsed || []), room?.id],
    })
  }, [channelsCollapsed, dispatch, room])

  const isHideSidebar = useMemo(
    () =>
      exclusiveMode &&
      roomGroupMeta?.rooms?.length === 1 &&
      roomMeta?.settings?.hideSidebar,
    [exclusiveMode, roomGroupMeta, roomMeta],
  )

  const handleNavigateToPosts = useCallback(
    () => history.push(APP_ROOM_POSTS(room?.slug)),
    [history, room],
  )
  const handleNavigateToChannels = useCallback(
    () => history.push(APP_ROOM_CHANNEL(room?.slug, channelId)),
    [history, room, channelId],
  )

  return (
    <Column>
      <Row spaceBetween>
        <RoomListWrapper mb={4} onClick={handleCollapsedLeft}>
          <VscMenu
            color={darkMode ? theme.colors.white : theme.colors.blackSecondary}
            grayLight
            size={24}
          />
        </RoomListWrapper>
        <RoomListWrapper mb={4}>
          <div aria-hidden="true" role="button" onClick={handleCollapsedRight}>
            <Avatar
              size={40}
              src={me.profile?.photoUrl}
              username={me.username}
            />
          </div>
        </RoomListWrapper>
      </Row>
      <Row center spaceBetween width={1}>
        {isHideSidebar && (
          <Row center mb={2}>
            <NavButtonExclusiveMode
              active={roomPostsPage?.isExact}
              onClick={handleNavigateToPosts}
            >
              <Text bolder>Lobby</Text>
            </NavButtonExclusiveMode>
          </Row>
        )}
      </Row>
      <Row center spaceBetween width={1}>
        <Row center>
          {!isHideSidebar && isMobile && (
            <Button noEffectsIcon onClick={handleCollapseChannels}>
              <FiChevronLeft color={theme.colors.customDefault} />
            </Button>
          )}
          {isHideSidebar ? (
            <NavButtonExclusiveMode
              active={roomChannelsPage?.isExact}
              onClick={handleNavigateToChannels}
            >
              <Text bolder>Channels</Text>
            </NavButtonExclusiveMode>
          ) : (
            <Text bolder> Channels</Text>
          )}
        </Row>
        <Column>
          {!canChangeChannels && (
            <Button
              iconButton
              noEffectsIcon
              smallest
              stroke
              onClick={() => onChannelCategory(room?.id)}
            >
              <IconWrapper>
                <FiPlus />
              </IconWrapper>
            </Button>
          )}
        </Column>
      </Row>
    </Column>
  )
}

Header.defaultProps = {
  canChangeChannels: false,
  channelId: '',
  room: null,
  onChannelCategory: noop,
}

Header.propTypes = {
  canChangeChannels: PropTypes.bool,
  channelId: PropTypes.string,
  room: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChannelCategory: PropTypes.func,
}

export default Header
