import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { Button, Modal, Row, Text } from 'Components/UI'
import { AudioInputField } from 'Components/UI/Forms'

import { ATTACH_TYPES, UPLOAD_TYPES } from 'Constants/ids'

import { useScopedI18n } from 'Services/I18n'

import PollForm from './Poll'
import { ModalContent, ModalSubtitle } from './styles'

function AttachmentModal({
  attachType,
  channelId,
  name,
  roomId,
  setForm,
  setLoadingRef,
  translationRef,
  messagesVariables,
  ...rest
}) {
  const s = useScopedI18n(`${translationRef}`)

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const handleClose = useCallback(() => close.current(), [])

  const modalTitle = useMemo(() => {
    switch (attachType) {
      case ATTACH_TYPES.IMAGE:
        return s('uploadImage')
      case ATTACH_TYPES.AUDIO:
        return s('postAudio')
      case ATTACH_TYPES.POLL:
        return s('postPoll')
      default:
        return 'Attachment'
    }
  }, [attachType, s])

  const renderModalContent = useMemo(() => {
    switch (attachType) {
      case ATTACH_TYPES.AUDIO:
        return (
          <ModalContent>
            <ModalSubtitle ml={1}>
              <Text alignStart mb={16} mt={24}>
                {s('postAudioSubtitle')}
              </Text>
            </ModalSubtitle>
            <AudioInputField
              entityId={roomId || channelId}
              name={name}
              uploadType={UPLOAD_TYPES.voiceAttachment}
            />
          </ModalContent>
        )
      case ATTACH_TYPES.POLL:
        return (
          <PollForm
            channelId={channelId}
            closeRef={close}
            messagesVariables={messagesVariables}
            roomId={roomId}
            setForm={setForm}
          />
        )
      default:
        return null
    }
  }, [attachType, channelId, messagesVariables, name, roomId, s, setForm])

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick
      title={modalTitle}
      onMount={handleMount}
    >
      {renderModalContent}
      {attachType !== ATTACH_TYPES.POLL && (
        <Row justifyCenter mb={16}>
          <Button onClick={handleClose}>Ok</Button>
        </Row>
      )}
    </Modal>
  )
}

AttachmentModal.defaultProps = {
  attachType: '',
  channelId: null,
  messagesVariables: null,
  name: '',
  roomId: null,
  setForm: null,
  setLoadingRef: null,
  translationRef: '',
}

AttachmentModal.propTypes = {
  attachType: PropTypes.string,
  channelId: PropTypes.string,
  messagesVariables: PropTypes.object,
  name: PropTypes.string,
  roomId: PropTypes.string,
  setForm: PropTypes.func,
  setLoadingRef: PropTypes.func,
  translationRef: PropTypes.string,
}

export default AttachmentModal
