import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { FiBell } from 'react-icons/fi'

import { useStoreon } from 'storeon/react'

import {
  CreateNotificationRule,
  NotificationRulesModal,
} from 'Components/Blocks/User/Modals'
import { Button, Column, Row, Text } from 'Components/UI'

import { UI_ACTIONS, UI_STATE } from 'Constants/store'

import unreadNotificationsCountQuery from 'GraphQL/Queries/Notifications/unreadNotificationsCount.graphql'

import { useAppContext, useEntranceContext } from 'Hooks'

import { GoBackButton, StyledGoBackIcon } from 'Pages/UIKit/styles'

import { useQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'

import Balances from './Balances'
import Controls from './Controls'
import Devices from './Devices'
import Info from './Info'
import Notifications from './Notifications'
import NotificationsCountSubscription from './NotificationsCounterSubscription'
import Palette from './Palette'
import {
  ButtonWrapper,
  CollapseArrow,
  Container,
  Content,
  NotificationWrapper,
  ScrollingContainer,
  UnreadWrapper,
} from './styles'
import TermsAndPrivacy from './TermsAndPrivacy'
import Wallets from './Wallets'

function AsideProfile({ isCollapsed }) {
  const [{ exclusiveMode }] = useEntranceContext()
  const { me } = useAppContext()
  const s = useScopedI18n('user.profile')
  const { dispatch, ui } = useStoreon(UI_STATE)
  const { darkMode, displayBalance } = ui
  const [notificationRulesModal, setNotificationRulesModal] = useState({
    isOpen: false,
  })

  const [createNotificationRuleModal, setCreateNotificationRuleModal] =
    useState({ isOpen: false, entity: null, rulesVariables: null })

  const { data } = useQuery(unreadNotificationsCountQuery)

  const unreadNotificationsCount = useMemo(
    () => data?.unreadNotificationsCount ?? {},
    [data?.unreadNotificationsCount],
  )

  const balanceUpdatedAt = useMemo(() => {
    if (!me?.balanceAutoUpdatedAt) {
      return me?.balancesManualUpdatedAt
    }
    return me?.balancesManualUpdatedAt > me?.balanceAutoUpdatedAt
      ? me?.balancesManualUpdatedAt
      : me?.balanceAutoUpdatedAt
  }, [me])

  const handleNotificationRules = useCallback(() => {
    setNotificationRulesModal({ isOpen: true })
  }, [])
  const handleCloseNotificationRulesModal = useCallback(() => {
    setNotificationRulesModal({ isOpen: false })
  }, [])

  const handleCreateNotificationRule = useCallback(
    ({ close, entity, rulesVariables }) => {
      setCreateNotificationRuleModal({ isOpen: true, entity, rulesVariables })
      close.current()
    },
    [],
  )
  const handleCloseCreateNotificationRuleModal = useCallback(() => {
    setCreateNotificationRuleModal({
      isOpen: false,
      entity: null,
      rulesVariables: null,
    })
    setNotificationRulesModal({ isOpen: true })
  }, [])

  const handleCollapsed = useCallback(() => {
    dispatch(UI_ACTIONS.SET, {
      rightIsCollapsed: !isCollapsed,
    })
  }, [dispatch, isCollapsed])

  const handleChangeDisplay = useCallback(
    value => {
      dispatch(UI_ACTIONS.SET, {
        displayBalance: value,
      })
    },
    [dispatch],
  )

  return (
    <>
      <Row alignItems="center" mb={5}>
        <GoBackButton onClick={handleCollapsed}>
          <StyledGoBackIcon />
        </GoBackButton>
        <Text big bolder ml={[2, 2, 2, 2, 0]}>
          {s('fields.profile')}
        </Text>
      </Row>

      <Container>
        <ScrollingContainer>
          <Content>
            <Content className="tutorial-fourth">
              <Info
                darkMode={darkMode}
                isCollapsed={isCollapsed}
                me={me}
                profile={me?.profile}
                userId={me?.id}
                username={me?.username}
              />
            </Content>
            {!isCollapsed && (
              <Row mt={12} spaceBetween>
                <Button
                  activeTab={!!displayBalance}
                  dark
                  height={32}
                  mr={9}
                  small
                  width={125}
                  onClick={() => handleChangeDisplay(true)}
                >
                  {s('actions.balance')}
                </Button>
                <Button
                  activeTab={!displayBalance}
                  dark
                  height={32}
                  small
                  width={125}
                  onClick={() => handleChangeDisplay(false)}
                >
                  {s('actions.settings')}
                </Button>
              </Row>
            )}
            {!displayBalance && (
              <Column mt={!isCollapsed ? 12 : 2}>
                {/* <Password isCollapsed={isCollapsed} me={me} /> */}
                {/* <DonationWallet
                  donationWallet={me?.profile?.donationWalletAddress}
                  isCollapsed={isCollapsed}
                  me={me}
                /> */}
                <Devices isCollapsed={isCollapsed} me={me} />
                {!exclusiveMode && (
                  <Palette isCollapsed={isCollapsed} me={me} />
                )}
              </Column>
            )}
            {!!displayBalance && (
              <Column mt={!isCollapsed ? 12 : 2}>
                <Balances
                  isCollapsed={isCollapsed}
                  updatedAt={balanceUpdatedAt}
                />
                <Wallets isCollapsed={isCollapsed} me={me} />
              </Column>
            )}
          </Content>
          <Column mt={2}>
            {!displayBalance && <TermsAndPrivacy isCollapsed={isCollapsed} />}
            <Controls
              displayBalance={displayBalance}
              isCollapsed={isCollapsed}
              userId={me?.id}
            />
          </Column>
        </ScrollingContainer>
        <ButtonWrapper>
          <Button arrows icon small onClick={handleCollapsed}>
            <CollapseArrow iconCollapsed={isCollapsed} strokeWidth={2} />
          </Button>
        </ButtonWrapper>
        {unreadNotificationsCount > 0 && (
          <UnreadWrapper collapsedUnread={isCollapsed}>
            <Text smallest white>
              {unreadNotificationsCount}
            </Text>
          </UnreadWrapper>
        )}
        <NotificationWrapper
          className="tutorial-fifth"
          collapsedWrapper={isCollapsed}
        >
          <Notifications
            handleRules={handleNotificationRules}
            offset={[-165, 2]}
            trigger="click"
            unreadCount={unreadNotificationsCount}
          >
            <Button
              height={!isCollapsed ? 32 : 22}
              iconStroke
              notification
              width={!isCollapsed ? 32 : 22}
            >
              <FiBell strokeWidth={2} />
            </Button>
          </Notifications>
        </NotificationWrapper>
        <NotificationRulesModal
          handleCreate={handleCreateNotificationRule}
          isOpen={notificationRulesModal?.isOpen}
          onClose={handleCloseNotificationRulesModal}
        />
        <CreateNotificationRule
          data={createNotificationRuleModal?.entity}
          isOpen={createNotificationRuleModal?.isOpen}
          rulesVariables={createNotificationRuleModal?.rulesVariables}
          onClose={handleCloseCreateNotificationRuleModal}
        />
        <NotificationsCountSubscription />
      </Container>
    </>
  )
}

AsideProfile.defaultProps = {
  isCollapsed: false,
}

AsideProfile.propTypes = {
  isCollapsed: PropTypes.bool,
}

export default AsideProfile
